body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #dcfff6;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#tsparticles {
    height: 100%;
    width: 100%;
}


.apexcharts-canvas {
    /**
    * Tooltip
    */
    .apexcharts-tooltip {
      min-width: 80px;
      border-radius: 10px;
      backdrop-filter: blur(6px);
      color: '#2C2C2C';
      box-shadow: #9cddcc 0px 0px 10px;
      background-color: #9cddcc;
    }
    .apexcharts-xaxistooltip {
      border-radius: 10px;
      border-color: transparent;
      backdrop-filter: blur(6px);
      color: '#2C2C2C';
      box-shadow: #9cddcc 0px 0px 10px;
      background-color: #9cddcc;
      &::before {
        border-bottom-color: "gray" / 0.16);
      }
      &::after {
        border-bottom-color: #9cddcc;;
      }
    }
    .apexcharts-tooltip-title {
      font-weight: 700;
      text-align: center;
      color: #00000;
      background-color: #9cddcc;
    }
    /**
    * Tooltip: group
    */
    .apexcharts-tooltip-series-group {
      padding: 4px 12px;
    }
    .apexcharts-tooltip-marker {
      margin-right: 8px;
    }
    /**
    * Legend
    */
    .apexcharts-legend {
      padding: 0;
    }
    .apexcharts-legend-marker {
      margin-right: 6px;
    }
    .apexcharts-legend-text {
      margin-left: 0;
      padding-left: 0;
      line-height: 18px;
    }
  }
  